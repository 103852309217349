import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { colors } from '../styles/colors';

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    -10px -10px 50px rgba(0, 0, 0, 0.5);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  @media (min-width: 1200px) {
    height: auto;
  }

  @media (max-width: 500px) {
    padding: 10px;
  }

  .photoDesktop {
    cursor: pointer;
    width: 380px;
    overflow: inherit !important;
    height: 200px;
    margin: 0px auto;
    object-fit: contain;
    margin-top: 10px;
    border-radius: 10px;
    align-self: center;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.5);

    @media (max-width: 1100px) {
      width: 300px;
    }

    @media (max-width: 500px) {
      width: 250px;
    }
  }

  .photoMobile {
    margin-top: -230px;
    margin-bottom: 20px;
    margin-left: 350px;
    width: 80px;
    border: 1px solid ${colors.defaultColor};
    background-color: rgb(59, 23, 61);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3),
      -10px -10px 30px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    @media (max-width: 1100px) {
      width: 50px;
      margin-left: 280px;
      margin-top: -140px;
    }

    @media (max-width: 500px) {
      width: 50px;
      margin-left: 240px;
      margin-top: -140px;
    }
  }

  .date_string {
    margin-top: 6px;
    font-size: 14px;
    color: burlywood;
    font-weight: 200;
  }

  .date_string a {
    text-decoration: none !important;
  }

  .cardText {
    border-radius: 10px;
    margin: 0px;
    width: 90%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1100px) {
      margin: 10px 10px;
      ${'' /* width: 280px; */}
    }
  }

  .technologies {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(8, 1fr);
  }

  h4 {
    margin-bottom: 0px;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 8px;
    padding-top: 16px;
    color: ${colors.defaultColor};
    line-height: 1.7rem;
    @media (max-width: 1100px) {
      margin-top: 25px;
      margin-bottom: 20px;
      ${'' /* text-align: center; */}
      ${'' /* padding: 20px; */}
    }
    @media (max-width: 760px) {
      margin-top: 0px;
      margin-bottom: 0px;
      ${'' /* text-align: center; */}
      ${'' /* padding: 20px; */}
    }
  }

  .code {
    border-color: transparent;
    border-bottom: 1px solid ${colors.defaultColor};
    border-radius: 0px;
  }

  .details {
    border: 1px solid ${colors.blue};
  }
`;

function Card({
  name, photoDesktop, photoMobile, seconds, onClick, date = null, subTitle = null,
}) {
  let mbImage;
  if (photoMobile) {
    mbImage = `https://admin.clockworkbee.co.uk/assets/${photoMobile.id}`;
  }
  const dskImage = getImage(photoDesktop.imageFile);
  const formattedDate = dayjs(date).format('dddd, MMMM D YYYY');

  return (
    <Container seconds={seconds}>
      {date && <div className="date_string">{formattedDate}</div>}
      <GatsbyImage
        objectFit="contain"
        className="photoDesktop"
        image={dskImage}
        onClick={onClick}
      />
      {mbImage && <img alt="prtScrMob" className="photoMobile" src={mbImage} />}
      <div className="cardText">
        <h3>{name}</h3>
        {subTitle && <h4 style={{ color: '#07f646' }}>{subTitle}</h4>}
      </div>
    </Container>
  );
}

export default Card;
